<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC"></div>
      <div class="headerR">
        <a href="javascript:void()"><img src="images/dph.png" /></a>
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div
    class="dpxqbox1"
    :style="
      options.shop.headImg
        ? 'background: url(' +
          options.shop.headImg +
          ') no-repeat;background-size: 100%;'
        : 'background: url(/images/dpbg.png) no-repeat;background-size: 100%;'
    "
  >
    <div class="dpxqbox1L">
      <div class="dpimg">
        <img :src="options.shop.logo ? options.shop.logo : 'images/dp1.png'" />
      </div>
      <div class="dptxt">
        <p class="p1">{{ options.shop.title }}</p>
        <p class="p2">
          {{
            options.shop.collectionNum == null ? 0 : options.shop.collectionNum
          }}人收藏
        </p>
      </div>
    </div>
    <div class="dpxqbox1R"></div>
    <div class="clear"></div>
  </div>
  <div class="clear"></div>
  <div class="dpsub" v-if="options.showType == 'index'">
    <!-- <div class="dpdengji">
      <ul>
        <li>
          <div class="v1">描述相符<span>4.7</span></div>
          <div class="v2">高</div>
        </li>
        <li>
          <div class="v1">质量满意<span>4.7</span></div>
          <div class="v2">高</div>
        </li>
        <li>
          <div class="v1">价格合理<span>4.7</span></div>
          <div class="v2">高</div>
        </li>
      </ul>
    </div> -->
    <div class="dpsub1">
      <div class="dpsubL">
        开店时间<span>{{
          stringUtils.dateFormat(options.shop.createTime, "yyyy-MM-DD HH:mm")
        }}</span>
      </div>
    </div>
    <div class="dpsub1 borbot">
      <div class="dpsubL">
        服务电话<span>{{ options.shop.contactPhone }}</span>
      </div>
      <div class="dpsubR">
        <img src="images/tel.png" />
      </div>
    </div>
  </div>
  <div class="kbox"></div>
  <div
    class="likebox"
    style="margin-bottom: 2rem"
    v-if="options.showType == 'index'"
  >
    <div class="likeTit">
      <span class="red">拍卖</span>
    </div>
    <van-pull-refresh v-model="options.refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="options.loading"
        :finished="options.finished"
        :offset="200"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <ul>
          <li v-for="a in options.auctions" :key="a.id">
            <router-link :to="'/auctionDetail/' + a.id">
              <van-image
                width="11rem"
                height="11rem"
                class="proimg"
                style="object-fit: cover"
                :src="a.pictureSmall"
              />
              <p class="tit" style="height: 1.8rem;">{{ a.title }}</p>
              <p class="price my" style="height: 3rem;">
                <vab-count-down
                  :startTime="a.startTime"
                  :endTime="a.endTime"
                ></vab-count-down>
              </p>
            </router-link>
          </li>
        </ul>
      </van-list>
    </van-pull-refresh>
  </div>
  <!-- <div class="shoplist" v-if="options.showType == 'auction'">
    <ul>
      <van-pull-refresh v-model="options.refreshing" @refresh="onRefresh">
        <van-list
          v-model:loading="options.loading"
          :finished="options.finished"
          :offset="200"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <li v-for="a in options.auctions" :key="a.id">
            <router-link :to="'/auctionDetail/' + a.id">
              <div class="listL">
                <van-image
                  width="7rem"
                  height="7rem"
                  class="proimg"
                  style="object-fit: cover"
                  :src="a.pictureSmall"
                />
              </div>
              <div class="listR" style="height: 7rem; position: static">
                <div class="v1">
                  {{ a.title }}
                </div>
                <div class="v2">
                  <span v-if="a.isDelayed == 1">延时</span
                  ><span v-if="a.isBond == 1">保证金</span>
                  <span v-if="a.isRed == 1">红包</span>
                </div>
                <div
                  class="v3"
                  style="
                    margin-bottom: 0;
                    position: absolute;
                    width: 100%;
                    margin-left: 7rem;
                    margin-top: 1rem;
                  "
                >
                  <p class="p1" v-if="a.status != 6" style="width: 100%">
                    <vab-count-down
                      :startTime="a.startTime"
                      :endTime="a.endTime"
                    ></vab-count-down>
                  </p>
                  <p class="p1" v-else>
                    <span class="title" style="width: 100%">已结束</span>
                  </p>
                  <p
                    class="p2"
                    v-if="a.bidCount > 0"
                    style="
                      width: 5rem;
                      margin-top: 0.4rem;
                      margin-left: 11rem;
                      position: absolute;
                    "
                  >
                    {{ a.bidCount }}轮竞拍
                  </p>
                </div>
              </div>
            </router-link>
          </li>
        </van-list>
      </van-pull-refresh>
    </ul>
  </div> -->
  <to-top></to-top>
</template>

<script>
import { reactive } from "vue";
import auctionApi from "@/api/auctionApi";
import shopApi from "@/api/shopApi";
import VabCountDown from "@/components/VabCountDown.vue";
import { useRoute } from "vue-router";
import { Toast } from "vant";
import ToTop from '@/components/ToTop.vue';
export default {
  components: {
    VabCountDown,
    ToTop,
  },
  setup() {
    const route = useRoute();
    const options = reactive({
      shop: {
        id: route.params.id,
        headImg: "",
        title: "",
        logo: "",
        contactPhone: "",
        memberId: "",
      },
      auctionTotal: 0,
      showType: "index",
      recommends: [],
      auctions: [],
      searchParams: {
        draw: 1,
        pageNo: 0,
        length: 10,
        order: "a",
        merchantId: "",
      },
      pageNo: 0,
      loading: false,
      finished: false,
      refreshing: false,
      pageCount: 1,
      total: 0,
      isSearch:false
    });
    const get = () => {
      shopApi.get({ shopId: route.params.id }).then((res) => {
        if (res.status == 200) {
          options.shop = res.result;
          options.searchParams.merchantId = options.shop.memberId;
          options.isSearch=true;
          onRefresh();
          getShopAuctionTotal();
        } else {
          Toast.fail(res.message);
        }
      });
    };
    const getShopAuctionTotal = () => {
      auctionApi
        .getShopAuctionTotal({ shopId: options.searchParams.merchantId })
        .then((res) => {
          if (res.status == 200) {
            options.auctionTotal = res.result;
          }
        });
    };
    const showTypeHandle = (type) => {
      options.showType = type;
      //   if (type == "goods") {
      //     myGoods();
      //   } else if (type == "auction") {
      //     myAuction();
      //   }
    };
    // const getRecommend = () => {
    //   shopApi.recommend({ shopId: options.shop.id }).then((res) => {
    //     if (res.status == 200) {
    //       options.recommends = res.result;
    //     }
    //   });
    // };
    async function onRefresh() {
      options.finished = false;
      options.loading = true;
      options.refreshing = true;
      onLoad();
    }
    async function onLoad() {
      console.log("refreshing:" + options.refreshing);
      if(!options.isSearch){
        return;
      }
      if (options.refreshing) {
        options.auctions = [];
        options.pageNo = 1;
        options.pageCount = 1;
        options.refreshing = false;
      } else {
        if (options.pageNo < options.pageCount) {
          options.pageNo = options.pageNo + 1;
        }
      }
      options.searchParams.pageNo = options.pageNo - 1;
      console.log(options.auctions.length + ":" + options.total);
      if (
        options.auctions.length > 0 &&
        options.total > 0 &&
        options.auctions.length >= options.total
      ) {
        options.finished = true;
        options.loading = false;
        return;
      }
      await auctionApi.search(options.searchParams).then((res) => {
        if (res.status == 200) {
          options.total = res.recordsTotal;
          let count = Math.ceil(res.recordsTotal / options.searchParams.length);
          options.pageCount = count;
          if (res.data.length > 0) {
            res.data.forEach((item) => {
              if (options.auctions.length < res.recordsTotal) {
                options.auctions.push(item);
              }
            });
            options.loading = false;
            if (options.auctions.length == res.recordsTotal) {
              options.finished = true;
            }
          } else {
            options.loading = false;
            options.finished = true;
          }
        } else {
          options.loading = false;
          options.finished = true;
        }
      });
    }
    get();
    return {
      options,
      get,
      showTypeHandle,
      onRefresh,
      onLoad,
    };
  },
};
</script>

<style>
.my .title{
  width: 100%;
}
.my .van-count-down{
  height: 2rem;
}
.my .block{
  width: 1.1rem;
  padding-left: 0 !important;
}
.my .colon{
  width: 1rem;
  padding-left: 0 !important;
}
</style>